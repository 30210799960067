import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

const StyledHero = styled.header`

    .textBlock {
      display: grid;
      grid-area: 1/1;
      position: relative;
      place-items: left;
      height: 500px;
      align-items: end;
      padding: 40px 20px 0;
      width: 50%;

      ${breakpoint.small`
        align-items: center;
        padding: 60px;
        width: 100%;
      `}
  
      ${breakpoint.medium`
        height: 650px;
      `}
    
      ${breakpoint.large`
        height: 750px;
      `}
    
      > div {
        width: 100%;

      ${breakpoint.small`
        max-width: 400px;
      `}

        ${breakpoint.medium`
          max-width: 600px;
        `}
      
    h1 { 
      font-size: 45px;
      margin-bottom: 1rem;
      color: ${colors.white};
      font-weight: 400!important;
      line-height: 1.1em;

      ${breakpoint.medium`
        margin-bottom: 1.5rem;
        font-size: 55px;
      `}

      ${breakpoint.large`
        font-size: 62px;
      `}
    }

    p { 
      margin-bottom: 1.5rem;
      color: ${colors.white};
      font-size: 20px;
      line-height: 1.2em;

      ${breakpoint.medium`
        font-size: 22px;
      `}

      ${breakpoint.large`
        font-size: 25px;
      `}
    }
  }

`

const Hero = () => {
  return (
    <StyledHero>
      <div style={{ display: "grid", width: "100%" }}>
        <StaticImage
          src="../../../assets/images/respiratory-health-test/hero.jpg"
          alt="Doctor consulting patient."
          style={{
            gridArea: "1/1",
            maxHeight: 750,
          }}
          layout="fullWidth"
          aspectRatio={2 / 1}
          objectPosition="center top"
        />
        <div className="textBlock">
          <div  data-aos="fade-up">
            <h1>Accurate<br />PCR&nbsp;results<br />without&nbsp;the instrument</h1>
            <p>Test to treat in a single<br />patient visit.</p>
          </div>
        </div>
      </div>
    </StyledHero>
  )
}

export default Hero
